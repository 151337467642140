/* eslint-disable @typescript-eslint/naming-convention */
import React, {useEffect, useRef, useState} from 'react';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.min.css';
import './index.css';
import {
  ApplicationScenarioInfoList,
  ProductAbilityInfoCardList,
  PartnerLogoList, CurrentCardListLeftPosition
} from '../../config/productAbilityCardList';
import qcodeImage from '../../common/img/icon_qcode.png';
import {report} from '../../util/report';
import yuewenImage from '../../common/img/business-partner_yuewen.png';
import tencentQcloudImage from '../../common/img/business-partner_qcloud.png';
import uaLogoImage from '../../common/img/business-partner_ua.png';
import animationPerson from '../../common/img/icon_translate_animation_person.png';

// @ts-ignore
import translateAnimationVideo from '../../common/video/translate_animation.mp4';
import {Link} from "react-router-dom";

let swiper: any;
let _timer: any;
export default function ProductIntroduce() {
  const [currentTabPartner, setCurrentTabPartner] = useState('yuewen');
  const subProductListRef = useRef<any>(null);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  useEffect(() => {
    swiper = new Swiper('.swiper-container', {
      // loop: true,
      effect: 'fade',
      speed: 600,
      autoplay: {
        delay: 5000, // 自动切换的延迟时间（毫秒）
        disableOnInteraction: false // 用户交互时是否禁用自动切换
      },
      on: {
        slideChange: function (swiper: any) {
          setCurrentTabPartner(PartnerLogoList[swiper.activeIndex]?.key);
        }
      }
    });

    updateSubProductList();
    const handleResize = () => {
      updateSubProductList();
    };

    window.addEventListener('resize', handleResize);

    const handleScrollResize = () => {
      const left = subProductListRef.current.scrollLeft;
      CurrentCardListLeftPosition.map((item, index) => {
        if (left > item && left < CurrentCardListLeftPosition[index + 1]) {
          setCurrentCardIndex(index);
        }
      });
    };

    subProductListRef.current.addEventListener('scroll', handleScrollResize);

    return () => {
      swiper.destroy(); // 在组件卸载时销毁 swiper 实例
      if (_timer) {
        clearInterval(_timer);
        _timer = null;
      }
      window.removeEventListener('resize', handleResize);
      subProductListRef.current && subProductListRef.current.removeEventListener('scroll', handleScrollResize);
    };
  }, []);

  const updateSubProductList = () => {
    const padding = (window.innerWidth - 1200) / 2;
    if (padding && !isNaN(padding) && subProductListRef) {
      subProductListRef.current.style.padding = `10px ${(padding)}px 50px`;
    }
  };

  const navigateToPartner = (item: any, index: number) => {
    setCurrentTabPartner(item.key);
    swiper.slideTo(index, 1000, false);
  };

  const openNewTabPage = (url: string) => {
    window.open(url, '_blank');
  };

  const immediateAccess = () => {
    report('click immediateAccess btn', 'product page');
    openNewTabPage('https://cloud.tencent.com/product/tmt');
  };

  return (
    <div className='page-product'>
      <div className='product-introduce'>
        <video muted loop autoPlay preload='auto'>
          <source src={translateAnimationVideo} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
        <img className='animation-person' src={animationPerson} alt=""/>
        <div className='product-introduce-animation'>
          <div className='introduce-content'>
            <div className='container'>
              <div className='introduce-detail'>
                <div className='name'>腾讯翻译君</div>
                <div className='target'>跨越语言，连接世界</div>
                <div
                  className='detail'>基于百亿级数据积累和领先算法，腾讯翻译君向客户提供翻译API、腾讯同传等产品服务，适用于直播同传、海外内容翻译等场景。同时，我们也提供翻译小程序、翻译app产品，帮助用户方便、快捷地体验翻译能力。
                </div>
                <div className='option'>
                  <button type='button' className='primary' onClick={() => immediateAccess()}>立即接入</button>
                  <button type='button' onClick={() => openNewTabPage('https://wj.qq.com/s2/14443422/3e7d')}>合作咨询
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='sub-product-list'>
            <ul
              ref={subProductListRef}
              className='sub-product-card-list'
            >
              {
                ProductAbilityInfoCardList.map((item) => {
                  return <li>
                    <div className={`title ${item?.iconClassName}`}>{item?.title}</div>
                    <div className='detail'>{item?.detail}</div>
                  </li>;
                })
              }
            </ul>
            <div className='card-circle-box'>
              <div className='container'>
                <ul className='card-circle'>
                  {
                    ProductAbilityInfoCardList.map((item, index) => {
                      return item && currentCardIndex !== -1 ? <li className={`card-circle-list ${currentCardIndex === index ? 'active' : ''}`}/> : null;
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='other-product-introduce'>
        <div className='application-scenario'>
          <div className='container'>
            <div className='section-header'>应用场景</div>
            <div className='section-subheading'>为不同需求的个人用户和各行各业的企业客户提供全面的翻译解决方案</div>
            <ul>
              {
                ApplicationScenarioInfoList.map(item => {
                  return <li>
                    <img src={item?.img}/>
                    <div className='text'>
                      <div className='title'>{item?.title}</div>
                      <div className='detail'>{item?.detail}</div>
                    </div>
                  </li>;
                })
              }
            </ul>
          </div>
        </div>
        <div className='business-partner'>
          <div className='container'>
            <div className='section-header'>合作伙伴</div>
            <div className='section-subheading'>服务上百家客户，链接上亿用户</div>
            <ul className='partner-logo-list'>
              {
                PartnerLogoList.map((item, index) => {
                  return <li className={item.key === currentTabPartner ? 'active' : ''}
                             onClick={() => navigateToPartner(item, index)}>
                    <img src={item?.img} alt=""/>
                  </li>;
                })
              }
            </ul>
            <div className='swiper-content'>
              <div className="swiper-container">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className='partner-detail'>
                      <div className='partner-introduce'>
                        <div className='partner-logo logo-yuewen'/>
                        <div
                          className='partner-introduce-text'>国内排名第一的正版数字阅读平台，将通过网文定制翻译引擎赋能海外编辑人员，已支持上千部出海的小说作品翻译。通过大量数据的训练和模型定制优化，提升在小说领域的翻译质量。
                        </div>
                      </div>
                      <div className='partner-img'>
                        <img src={yuewenImage} alt=""/>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className='partner-detail'>
                      <div className='partner-introduce'>
                        <div className='partner-logo logo-ua'/>
                        <div
                          className='partner-introduce-text'>接入联合国文件署人工翻译平台，辅助人工译者进行翻译工作，内容覆盖会议记录、报告、决议等，日均翻译字数提升10倍以上。同时，根据联合国的具体需求，还提供了定制化的翻译模型和术语库，确保翻译结果的专业性和一致性。
                        </div>
                      </div>
                      <div className='partner-img'>
                        <img src={uaLogoImage} alt=""/>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className='partner-detail'>
                      <div className='partner-introduce'>
                        <div className='partner-logo logo-tencent-qcloud'/>
                        <div
                          className='partner-introduce-text'>接入腾讯云人工翻译平台，辅助人工译者翻译腾讯云的技术文档、产品服务介绍材料，可支持html、md文件的翻译，支持定制翻译数据。
                        </div>
                      </div>
                      <div className='partner-img'>
                        <img src={tencentQcloudImage} alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='content-bottom'>
          <div className='container'>
            <div className='content-detail'>
              <div className='content-left'>
                <div className='list-item'>
                  <div className='title'>产品服务</div>
                  <ul>
                    <li>
                      <Link to="/" target="_blank">在线翻译</Link>
                    </li>
                    <li>
                      <Link to="https://cloud.tencent.com/product/tmt" target="_blank">翻译API</Link>
                    </li>
                    <li>
                      <Link to="https://cloud.tencent.com/product/tsi" target="_blank">腾讯同传</Link>
                    </li>
                  </ul>
                </div>
                <div className='list-item'>
                  <div className='title'>联系我们</div>
                  <ul>
                    <li>translate@tencent.com</li>
                    <li>腾讯云计算（北京）有限责任公司</li>
                  </ul>
                </div>
              </div>
              <div className='content-right'>
                <img src={qcodeImage} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
