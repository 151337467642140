import React, {useEffect, useState} from 'react';
import './App.css';
import '@tencent/tea-component/lib/tea.css';
import type {RouteObject} from 'react-router-dom';
import {Outlet, Link, useRoutes, useLocation} from 'react-router-dom';
// import Home from "@src/routes/tmt_demo";
import TmtDemo from './routes/tmt_demo';
import ProductIntroduce from './routes/product_introduce';
import logo from './common/img/icon_header_logo.png';

export default function App() {
  const routes: RouteObject[] = [
    {
      path: '/',
      element: <Layout/>,
      children: [
        {index: true, element: <TmtDemo/>},
        {
          path: '/productIntroduce',
          element: <ProductIntroduce/>
        },
        {path: '*', element: <TmtDemo/>},
      ],
    },
  ];

  // The useRoutes() hook allows you to define your routes as JavaScript objects
  // instead of <Routes> and <Route> elements. This is really just a style
  // preference for those who prefer to not use JSX for their routes config.
  const element = useRoutes(routes);

  return (
    <div style={{height: '100%'}}>
      {element}
    </div>
  );
}

function Layout() {
  const location = useLocation();
  const [windowIsScrollTop, setWindowIsScrollTop] = useState(true);
  const [isSingleScreen, setIsSingleScreen] = useState(false);

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    setIsSingleScreen(windowHeight >= documentHeight);
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setWindowIsScrollTop(scrollTop === 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);

  return (
    <div style={{height: '100%'}}>
      <nav
        className={`nav-content ${windowIsScrollTop ? '' : 'scroll-bg'} ${isSingleScreen ? 'one-screen' : ''}`}>
        <div className='container nav-container'>
          <div className='nav-left'>
            <img src={logo} alt=""/>
          </div>
          <ul className='nav-right'>
            <li className={location.pathname === '/' ? 'active' : ''}><Link to="/">在线翻译</Link></li>
            <li className={location.pathname === '/productIntroduce' ? 'active' : ''}><Link
              to="/productIntroduce">产品介绍</Link></li>
            <li className='outside-link'><Link to="https://cloud.tencent.com/product/tmt">翻译API</Link></li>
          </ul>
        </div>
      </nav>
      <Outlet/>
    </div>
  );
}

