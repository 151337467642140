/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/naming-convention */
import React, {useEffect, useRef, useState} from 'react';
import './index.css';
import {Dropdown, Card, List, TextArea, Copy, Bubble, message} from '@tencent/tea-component';
import {LanguageList, LanguageCorrespondence} from '../../config/languageConfig';
import {request} from '../../common/api';

const sourceChangeDelay = 500;
let isClickChooseSourceLanguage = false;
let isRequest = true;
let _timer = null;
let sourceTextStr = '';
const baseUrl = window.location.href.includes('localhost') ? 'http://21.0.21.242' : '';
export default function TmtDemo() {
  const [sourceText, setSourceText] = useState('');
  const [sourceTextList, setSourceTextList] = useState([]);
  const [targetTextList, setTargetTextList] = useState([]);
  const sourceTextAreaRef = useRef<any>(null);
  const sourceTextAreaBoxRef = useRef<any>(null);
  const targetTextAreaRef = useRef<any>(null);
  const [sourceLanguage, setSourceLanguage] = useState('auto');
  const [targetLanguage, setTargetLanguage] = useState('zh');
  const [isBilingualControl, setIsBilingualControl] = useState(false);
  const [isShowScrollbar, setIsShowScrollbar] = useState(false);
  // const [isShowBrandModal, setIsShowBrandModal] = useState(false);
  const sourceList = (close: any) => (
    <Card>
      <Card.Body>
        <List>
          {
            LanguageCorrespondence.auto?.map((item: any, index: number) => {
              return <List.Item key={index}
                                onClick={() => chooseSourceLanguageHandle(item, true, close)}>{LanguageList[item]}</List.Item>;
            })
          }
        </List>
      </Card.Body>
    </Card>
  );
  const targetList = (close: any) => (
    <Card>
      <Card.Body>
        <List>
          {
            LanguageCorrespondence[sourceLanguage]?.filter((list: any) => list !== 'auto')
              .map((item: any, index: number) => {
                return <List.Item key={index}
                                  onClick={() => chooseTargetLanguageHandle(item, close)}>{LanguageList[item]}</List.Item>;
              })
          }
        </List>
      </Card.Body>
    </Card>
  );

  useEffect(() => {
    getTranslateTokenHandle();
    // isShowModalHandle();
  }, []);

  const chooseSourceLanguageHandle = (source: string, isClickTrigger: boolean, callback?: any) => {
    try {
      if (isClickTrigger) {
        if (source === 'auto') {
          isClickChooseSourceLanguage = false;
        } else {
          isClickChooseSourceLanguage = true;
        }
      } else {
        isClickChooseSourceLanguage = false;
      }
      setSourceLanguage(source || '');
      let list = LanguageCorrespondence[source];
      if (source === 'auto') {
        list = LanguageCorrespondence[source]?.filter((list: any) => list !== 'auto');
      }
      list.length > 0 && setTargetLanguage(list[0]);
      if (callback) callback();
    } catch (e) {
      console.log(e);
    }
  };

  const chooseTargetLanguageHandle = (source: string, callback: any) => {
    try {
      setTargetLanguage(source || '');
      setTargetTextList([]);
      if (callback) callback();
    } catch (e) {
      console.log(e);
    }
  };


  // 输入框变化时
  const sourceTextChange = (text: string) => {
    try {
      if (text.length === 0) {
        setSourceTextList([]);
        setIsBilingualControl(false);
        setTargetTextList([]);
      }
      setSourceText(text);
      sourceTextStr = text;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    try {
      if (sourceText.length === 0) {
        sourceTextAreaRef.current.style.height = `298px`;
        targetTextAreaRef.current.style.height = `298px`;
        sourceTextAreaBoxRef.current.style.height = `410px`;
        return;
      }
      const maxHeight = window.innerHeight - 348;
      const leftCurrent = sourceTextAreaRef?.current;
      const rightCurrent = targetTextAreaRef?.current;
      if (leftCurrent && leftCurrent.style) {
        leftCurrent.style.height = 'auto';
        rightCurrent.style.height = 'auto';
        sourceTextAreaBoxRef.current.style.height = 'auto';
      }
      const leftScrollHeight = sourceTextAreaRef?.current?.scrollHeight;
      const rightScrollHeight = targetTextAreaRef?.current?.scrollHeight;
      requestAnimationFrame(() => {
        if (rightScrollHeight > leftScrollHeight)  {
          if (rightScrollHeight < maxHeight) {
            sourceTextAreaRef.current.style.height = `${rightScrollHeight}px`;
            targetTextAreaRef.current.style.height = `${rightScrollHeight}px`;
            sourceTextAreaBoxRef.current.style.height = `${rightScrollHeight + 130}px`;
            setIsShowScrollbar(false);
          } else {
            sourceTextAreaRef.current.style.height = `${maxHeight}px`;
            targetTextAreaRef.current.style.height = `${maxHeight}px`;
            sourceTextAreaBoxRef.current.style.height = `${maxHeight + 130}px`;
            setIsShowScrollbar(true);
          }
        } else {
          if (leftScrollHeight > maxHeight) {
            sourceTextAreaRef.current.style.height = `${maxHeight}px`;
            targetTextAreaRef.current.style.height = `${maxHeight}px`;
            sourceTextAreaBoxRef.current.style.height = `${maxHeight + 130}px`;
            setIsShowScrollbar(true);
          } else {
            sourceTextAreaRef.current.style.height = `${leftScrollHeight}px`;
            targetTextAreaRef.current.style.height = `${leftScrollHeight}px`;
            sourceTextAreaBoxRef.current.style.height = `${leftScrollHeight + 130}px`;
            setIsShowScrollbar(false);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }, [sourceTextList, sourceText, targetTextList, isBilingualControl]);

  useEffect(() => {
    try {
      if (isRequest && sourceText) {
        isRequest = false;
        _timer = setTimeout(() => {
          isRequest = true;
          if (!isClickChooseSourceLanguage) {
            getLanguageTypeHandle(sourceTextStr);
          } else {
            getTranslateResult(sourceTextStr);
          }
          _timer = null;
        }, sourceChangeDelay);
      }
    } catch (e) {
      console.log(e);
    }
  }, [sourceText]);

  useEffect(() => {
    getTranslateResult(sourceText);
  }, [sourceLanguage, targetLanguage]);

  const getLanguageTypeHandle = async (text: string) => {
    try {
      setSourceLanguage('auto');
      const tokenInfo = localStorage.getItem('tokenInfo');
      const currentText = text || '';
      let textNoLine = '';
      currentText && (textNoLine = currentText.replaceAll('\n', ''));
      if (!tokenInfo || !currentText || !textNoLine) {
        return false;
      }
      const tokenInfoObj = JSON.parse(tokenInfo);
      const dataParams = {
        Text: textNoLine,
        Token: tokenInfoObj.Token || ''
      };
      const res = await request(`${baseUrl}/api/v1/translate/detect`, 'post', dataParams);
      if (res?.Code === 0 || res?.Code === 2000) {
        setSourceLanguage(res?.Data?.Lang || 'auto');
        chooseSourceLanguageHandle(res?.Data?.Lang, false);
        res?.Code === 2000 && getTranslateTokenRequest();
      } else if (res?.Code === 2004) { // token失效
        getTranslateTokenRequest(getTicketHandleAndRequest);
      } else {
        message.error({
          content: res?.Message || '服务错误',
          className: 'error-message'
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  //
  const getTranslateTokenHandle = () => {
    const tokenInfo: any = localStorage.getItem('tokenInfo');
    if (!tokenInfo) { // 未存储token信息
      getTranslateTokenRequest();
    } else {
      const tokenObj = JSON.parse(tokenInfo);
      if (!tokenObj.Expiration || tokenObj.Expiration * 1000 < new Date().getTime()) {
        getTranslateTokenRequest();
      }
    }
  };

  // 获取token
  const getTranslateTokenRequest = async (callback?: any) => {
    try {
      const captcha = new TencentCaptcha('191656418', callback || getTicketHandle, {loading: false});
      captcha.show();
    } catch (e) {
      console.log(e);
    }
  };

  const getTicketHandleAndRequest = async (res: any) => {
    await getTicketHandle(res);
    getLanguageTypeHandle(sourceTextStr);
  };

  // 获取验证码之后的回调
  const getTicketHandle = async (res: any) => {
    if (res.ticket) {
      const params = {
        Ticket: res?.ticket || '',
        Randstr: res?.randstr || ''
      };
      try {
        const res = await request(`${baseUrl}/api/v1/token/create`, 'post', params);
        if (res.Code === 0) {
          const tokenInfo = {
            Token: res?.Data?.Token,
            Expiration: res?.Data?.Expiration,
          };
          localStorage.setItem('tokenInfo', JSON.stringify(tokenInfo));
        } else {
          throw res;
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  // 获取翻译结果
  const getTranslateResult = async (text: string) => {
    try {
      if (sourceLanguage === 'auto') {
        return false;
      }
      setTargetTextList([]);
      const tokenInfo = localStorage.getItem('tokenInfo');
      if (!tokenInfo || !text) {
        return false;
      }
      const tokenInfoObj = JSON.parse(tokenInfo);
      const arr: any = text.split('\n');
      const dataParams = {
        SourceTextList: arr,
        Source: sourceLanguage,
        Target: targetLanguage,
        Token: tokenInfoObj.Token || ''
      };
      const res = await request(`${baseUrl}/api/v1/translate/text`, 'post', dataParams);
      if (res.Code === 0 || res?.Code === 2000) {
        setSourceTextList(arr);
        const textList = res?.Data?.TargetTextList;
        setTargetTextList(textList);
        res?.Code === 2000 && getTranslateTokenRequest();
      } else if (res.Code === 2004) { // token失效
        getTranslateTokenRequest();
        setTimeout(() => {
          getTranslateResult(sourceText);
        }, 200);
      } else {
        message.error({
          content: res?.Message || '服务错误',
          className: 'error-message'
        });
      }
    } catch (e) {
      console.log(e, 'e');
    }
  };

  // 切换双语对照
  const toggleBilingualControl = () => {
    setIsBilingualControl(!isBilingualControl);
  };

  const copyTips = (copied: boolean) => {
    return copied ? <div className='icon_check'>
      复制成功
    </div> : '复制';
  };

  // 判断是否展示品牌升级弹窗
  // const isShowModalHandle = () => {
  //   const brandModalIsShow: any = localStorage.getItem('brandModalIsShow');
  //   if (!brandModalIsShow) {
  //     setIsShowBrandModal(true);
  //   }
  // };

  // const closeBrandModal = () => {
  //   setIsShowBrandModal(false);
  //   localStorage.setItem('brandModalIsShow', '1');
  // };

  // 复制
  const copyText = () => {
    const arrTextArr: any = [];
    targetTextList.map((item: string, index: number) => {
      if (isBilingualControl && sourceTextList[index]) {
        sourceTextList[index] !== '' && arrTextArr.push(`${sourceTextList[index]}\n`);
      }
      item !== '' && arrTextArr.push(`${item}\n`);
    });
    return arrTextArr.join('');
  };

  // 清楚原文
  const clearSourceText = () => {
    setSourceText('');
    sourceTextStr = '';
    setSourceTextList([]);
    setTargetTextList([]);
  }

  return (
    <div className='page-demo'>
      <div className='demo-container'>
        <ul className='header-tab'>
          <li className='text current'>
            <div className='title'>文本翻译</div>
          </li>
          <li className='image notOnline'>
            <div className='title'>图片翻译</div>
          </li>
          <li className='document notOnline'>
            <div className='title'>文档翻译</div>
          </li>
        </ul>
        <div className='translate-content' ref={sourceTextAreaBoxRef}>
          <div className='content-box content-left'>
            <div className='content-header-box'>
              <div className='content-header'>
                <Dropdown
                  trigger="click"
                  clickClose={false}
                  style={{marginRight: 10}}
                  button={`${LanguageList[sourceLanguage] || '自动识别'}${!isClickChooseSourceLanguage && sourceLanguage !== 'auto' && LanguageList[sourceLanguage] ? '（自动检测）' : ''}`}
                  size='full'
                  boxClassName='drop-down-box'
                >
                  {sourceList}
                </Dropdown>
              </div>
              {
                sourceText && (
                  <div className='icon-clear' onClick={clearSourceText}/>
                )
              }
            </div>
            <div className='content-input input-left'>
              <TextArea
                maxLength={2000}
                showCount
                size='full'
                ref={sourceTextAreaRef}
                className={`${sourceText.length > 60 ? 'font-size-16' : 'font-size-22'}`}
                value={sourceText}
                onChange={(value) => {
                  sourceTextChange(value);
                }}
                placeholder="输入文本内容"
              />
            </div>
          </div>
          <div className='content-box content-right'>
            <div className='content-header'>
              <Dropdown
                trigger="click"
                clickClose={false}
                style={{marginRight: 10}}
                button={LanguageList[targetLanguage]}
                size='full'
                boxClassName='drop-down-box'
              >
                {targetList}
              </Dropdown>
            </div>
            <div className='content-input input-right'>
              <div
                className={`target-text-area ${targetTextList.join('').length > 60 ? 'font-size-16' : 'font-size-22'}`}>
                <div className={`target-text-box ${isShowScrollbar ? '' : 'not-show'}`} ref={targetTextAreaRef}>
                  {
                    targetTextList.length > 0 && sourceText.replaceAll('\n', '') ?
                      targetTextList.map((item, index) => {
                        return sourceTextList[index] === '' ? <div className='target-text-list'/>
                          : <>
                            {
                              isBilingualControl &&
                                <div className='source-text-list'>{sourceTextList[index] || ''}</div>
                            }
                            <div className='target-text-list'>{item}</div>
                          </>;
                      })
                      : <div className='placeholder'>译文</div>
                  }
                </div>
              </div>
            </div>
            {
              targetTextList && targetTextList.length > 0 && (
                <div className='content-option'>
                  <Bubble
                    arrowPointAtCenter
                    content={isBilingualControl ? '关闭双语对照' : '开启双语对照'}
                  >
                    <div className='btn-content-box'>
                      <div className={`btn-compare ${isBilingualControl ? 'active' : ''}`}
                           onClick={() => toggleBilingualControl()}/>
                    </div>
                  </Bubble>
                  <div className='btn-content-box'>
                    <Copy tips={copyTips} text={copyText()}/>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
      {/*{*/}
      {/*  isShowBrandModal && (*/}
      {/*    <div className='window-modal-tips'>*/}
      {/*      <div className='tips-img'>*/}
      {/*        <div className='img'/>*/}
      {/*        <div className='tips-btn-close' onClick={() => closeBrandModal()}/>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  )*/}
      {/*}*/}
    </div>
  );
}
