import aegis from './aegis';

/**
 *
 * @param {String} name 日志标识
 * @param {String} ext1 日志内容
 * @param {String} ext2 日志内容
 * @param {String} ext3 日志内容
 */
export const report = (name, ext1, ext2 = '', ext3 = '') => {
  aegis.reportEvent({
    name,
    ext1,
    ext2,
    ext3,
  });
};

/**
 *
 * @param {String} uin 请求的唯一标识
 */
export const setUin = (uin) => {
  aegis.setConfig({
    uin
  });
};

export default {
  report,
  setUin
};
