/* eslint-disable @typescript-eslint/naming-convention */
interface LanguageListType {
  [key: string]: string;
}

interface LanguageCorrespondenceType {
  [key: string]: any;
}

// 语种数组
export const LanguageList: LanguageListType = {
  'auto': '自动识别',
  'zh': '简体中文',
  'zh-TW': '繁体中文',
  'en': '英语',
  'ja': '日语',
  'ko': '韩语',
  'fr': '法语',
  'es': '西班牙语',
  'de': '德语',
  'tr': '土耳其语',
  'ru': '俄语',
  'vi': '越南语',
  'id': '印尼语',
  'ms': '马来西亚语',
  'ar': '阿拉伯语',
  'hi': '印地语'
};
// 翻译语种对应关系，数组第一个元素为默认目标语言
export const LanguageCorrespondence: LanguageCorrespondenceType = {
  'auto': ['auto', 'zh', 'zh-TW', 'en', 'fr', 'de', 'ru', 'tr', 'es', 'ja', 'ko', 'vi', 'id', 'ms', 'ar', 'hi'],
  'zh': ['en', 'zh-TW', 'fr', 'de', 'ru', 'tr', 'es', 'ja', 'ko', 'vi', 'id', 'ms'],
  'zh-TW': ['en', 'zh', 'fr', 'de', 'ru', 'tr', 'es', 'ja', 'ko', 'vi', 'id', 'ms'],
  'en': ['zh', 'zh-TW', 'fr', 'de', 'ru', 'tr', 'es', 'ja', 'ko', 'vi', 'id', 'ms', 'ar', 'hi'],
  'fr': ['zh', 'zh-TW', 'en', 'de', 'ru', 'tr', 'es'],
  'de': ['zh', 'zh-TW', 'en', 'fr', 'ru', 'tr', 'es'],
  'ru': ['zh', 'zh-TW', 'en', 'fr', 'de', 'tr', 'es'],
  'tr': ['zh', 'zh-TW', 'en', 'fr', 'de', 'ru', 'es'],
  'es': ['zh', 'zh-TW', 'en', 'fr', 'de', 'ru', 'tr'],
  'ja': ['zh', 'zh-TW', 'en', 'ko'],
  'ko': ['zh', 'zh-TW', 'en', 'ja'],
  'vi': ['zh', 'zh-TW', 'en'],
  'id': ['zh', 'zh-TW', 'en'],
  'ms': ['zh', 'zh-TW', 'en'],
  'ar': ['en'],
  'hi': ['en'],
};
