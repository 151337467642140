/* eslint-disable @typescript-eslint/naming-convention */
import applicationMeetingImage from '../common/img/application_meeting.png';
import applicationGoToAbroadImage from '../common/img/application_goToAbroad.png';
import applicationMediaImage3 from '../common/img/application_media.png';
import applicationGameImage from '../common/img/application_game.png';
import applicationEduImage from '../common/img/application_edu.png';
import applicationJourneyImage from '../common/img/application_journey.png';
import yuewenImage from '../common/img/yuewen_logo_min.png';
import tencentQcloudImage from '../common/img/tencent_qcloud_logo_min.png';
import uaLogoImage from '../common/img/ua_logo_min.png';

export const ProductAbilityInfoCardList = [
  {
    title: '文本翻译',
    iconClassName: 'icon-text',
    detail: '将输入文本自动翻译成目标语言文本，并支持自定义术语翻译。'
  },
  {
    title: '图片翻译',
    iconClassName: 'icon-img',
    detail: '智能定位并识别出图片中的文字，将其翻译成目标语言文本。'
  },
  {
    title: '语音翻译',
    iconClassName: 'icon-audio',
    detail: '将一段源语言音频翻译成目标语言文本，边说边翻译。'
  },
  {
    title: '文件翻译',
    iconClassName: 'icon-file',
    detail: '将一个源语言文件翻译成目标语言文件，支持文件格式还原。'
  },
  {
    title: '音视频翻译',
    iconClassName: 'icon-audio-video',
    detail: '对一个音频或视频文件，提取音频内容进行转写，并自动翻译到目标语言。'
  },
  {
    title: '同声传译',
    iconClassName: 'icon-si',
    detail: '对实时音频流进行语音识别和翻译，支持对识别和翻译结果进行智能优化。'
  },
  {
    title: '翻译模型定制',
    iconClassName: 'icon-tmc',
    detail: '基于特定领域的语料数据训练翻译模型，提升在垂直场景的翻译质量。'
  }
];

export const ApplicationScenarioInfoList = [
  {
    img: applicationMeetingImage,
    title: '跨国会议',
    detail: '实时将与会者的发言翻译成其他语言，每个人都能理解并参与到讨论中。不仅提高了会议效率，也促进了国际间的合作与交流。'
  },
  {
    img: applicationGoToAbroadImage,
    title: '产品出海',
    detail: '帮助企业快速将产品说明、用户手册和网站内容翻译成目标市场的语言，降低成本，提高市场竞争力。'
  },
  {
    img: applicationMediaImage3,
    title: '社交媒体',
    detail: '帮助用户理解并参与到不同语言的讨论中，促进不同文化之间的交流和理解，打破语言的隔阂。'
  },
  {
    img: applicationGameImage,
    title: '游戏娱乐',
    detail: '将游戏文本翻译成玩家母语，提高玩家的游戏体验，扩大游戏的全球市场。'
  },
  {
    img: applicationEduImage,
    title: '在线教育',
    detail: '实时将优质的外语教育资源，如在线课程、教学视频和教材内容翻译成学习者的母语，轻松地理解和掌握知识，从而提高学习效果。'
  },
  {
    img: applicationJourneyImage,
    title: '出国旅行',
    detail: '可识别并翻译路标、指示牌、菜单、景点介绍等，帮助游客更好地理解当地文化和信息，享受更愉快的旅行体验。'
  }
];

export const CurrentCardListLeftPosition = [0, 200, 400, 600, 900, 1100, 1300, 1600];
export const CurrentCardListRightPosition = [-3000, -2400, -1999, -1600, -1200, -799, -398, 3];

export const PartnerLogoList = [
  {
    key: 'yuewen',
    img: yuewenImage
  },
  {
    key: 'ua',
    img: uaLogoImage
  },
  {
    key: 'tencent-qcloud',
    img: tencentQcloudImage
  }
];

