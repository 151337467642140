export function request(url: string, method: string, body: any) {
  return fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .catch(error => {
      console.error('Request error:', error);
      throw error;
    });
}
